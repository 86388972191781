import React, { Component } from 'react'
import { ContainerQuery } from 'react-container-query'
import classNames from 'clsx'
import PropTypes from 'prop-types'
import pathToRegexp from 'path-to-regexp'
// UI组件
import { Layout } from 'antd'
const { Content } = Layout
import { inject, observer } from 'mobx-react'
// 自定义组件
import Context from './MenuContext'
import SidebarMenu from '../Components/SidebarMenu'
// 自定义样式部分
import styles from './BasicLayout.module.less'

const query = {
  'screen-xs': {
    maxWidth: 575
  },
  'screen-sm': {
    minWidth: 576,
    maxWidth: 767
  },
  'screen-md': {
    minWidth: 768,
    maxWidth: 991
  },
  'screen-lg': {
    minWidth: 992,
    maxWidth: 1199
  },
  'screen-xl': {
    minWidth: 1200,
    maxWidth: 1599
  },
  'screen-xxl': {
    minWidth: 1600
  }
}
@inject(({ user }) => ({
  clickKey: user.clickKey,
  setClickKey: user.setClickKey
}))
@observer
class BasicLayout extends Component {
  getContext () {
    const { location, breadcrumbNameMap } = this.props
    return {
      location,
      breadcrumbNameMap
    }
  }

  getRouterAuthority = (pathname, routeData) => {
    let routeAuthority = ['noAuthority']
    const getAuthority = (key, routes) => {
      routes.map(route => {
        if (route.path && pathToRegexp(route.path).test(key)) {
          routeAuthority = route.authority
        } else if (route.routes) {
          routeAuthority = getAuthority(key, route.routes)
        }
        return route
      })
      return routeAuthority
    }
    return getAuthority(pathname, routeData)
  }

  getLayoutStyle = () => {
    const { collapsed, layout } = this.props
    if (this.fixSiderbar && layout !== 'topmenu') {
      return {
        paddingLeft: collapsed ? '80px' : '256px'
      }
    }
    return null
  }

  handleMenuCollapse = (collapsed, item) => {
    this.props.setClickKey(item.key)
  }
  render () {
    const {
      navTheme,
      // eslint-disable-next-line no-unused-vars
      layout: PropsLayout,
      children,
      menuData,
      // eslint-disable-next-line no-unused-vars
      location: { pathname }
    } = this.props
    const schoolType = sessionStorage.getItem('schoolType')
    const layout = (
      <Layout>
        {schoolType ? null : (
          <SidebarMenu
            theme={navTheme}
            onCollapse={this.handleMenuCollapse}
            menuData={menuData}
            {...this.props}
          />
        )}

        <Layout
          style={{
            ...this.getLayoutStyle(),
            minHeight: '100vh'
          }}
        >
          <Content
            className={schoolType ? styles.schoolContent : styles.content}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    )
    return (
      <React.Fragment>
        <ContainerQuery query={query}>
          {params => (
            <Context.Provider value={this.getContext()}>
              <div className={classNames(params)}>{layout}</div>
            </Context.Provider>
          )}
        </ContainerQuery>
      </React.Fragment>
    )
  }
}
BasicLayout.defaultProps = {
  collapsed: false,
  navTheme: 'dark',
  fixSiderbar: false,
  menuData: []
}
BasicLayout.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    key: PropTypes.string
  }),
  setClickKey: PropTypes.func,
  clickKey: PropTypes.string,
  pathname: PropTypes.string,
  breadcrumbNameMap: PropTypes.object,
  children: PropTypes.node.isRequired,
  collapsed: PropTypes.bool,
  routes: PropTypes.array,
  navTheme: PropTypes.oneOf(['light', 'dark']),
  layout: PropTypes.string,
  menuData: PropTypes.array,
  fixSiderbar: PropTypes.bool
}

export default BasicLayout
