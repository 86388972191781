import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import {
  BrowserRouter as Routers,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
// 自定义组件
import PageLoading from '../Components/PageLoading'
import { getQueryStrings } from '../utils/index'
import BasicLayout from '../Layouts/BasicLayout'
import Tv from '../Views/ManagePro/Substance/MoralEducation/tv'
// import { getQueryStrings } from '../utils/index'

const ModelModule = React.lazy(() => import('../Views/ManagePro/Model'))
const SubstanceModule = React.lazy(() => import('../Views/ManagePro/Substance'))
const AttendanceModule = React.lazy(() =>
  import('../Views/ManagePro/Attendance')
)
const FacilityModule = React.lazy(() => import('../Views/ManagePro/Facility'))
const MutualModule = React.lazy(() => import('../Views/ManagePro/Mutual'))
// const FrontEnd = React.lazy(() => import('../Views/FrontEnd/FrontEnd'))
const UploadRead = React.lazy(() =>
  import('../Views/ManagePro/UploadRead/index.js')
)

const BaseData = React.lazy(() => import('../Views/ManagePro/baseData/index'))
@inject(({ routers, setting, user }) => {
  return {
    currentUser: user.currentUser,
    requestState: user.state,
    fetchTokenValue: user.fetchTokenValue,
    getTocken: user.getTocken,
    menuData: routers.routerInfo,
    // schoolMenu: routers.fetchUserResource,
    navTheme: setting.settingInfo.navTheme,
    layout: setting.settingInfo.layout,
    fixSiderbar: setting.settingInfo.fixSiderbar,
    GetClassesAndGrades: user.fetchGetClassesAndGrades
  }
})
@observer
class Router extends Component {
  constructor(props) {
    super(props)
    this.state = {
      from: null
    }
  }
  async componentWillMount () {
    await this.props.fetchTokenValue()
    await this.props.GetClassesAndGrades()
    const { phone, tnId, from } = getQueryStrings()
    // 设置路由
    // const params = {
    //   schoolId: tnId,
    //   teacherCode: teacherCode,
    //   type: 1
    // }
    // let queryInfo = getQueryStrings()
    // if (queryInfo.action != 'xjj') {
    //   this.props.schoolMenu(params)
    // }
    if (from == 'saas') {
      this.setState({ from: from })
      if (from == 'yxt') {
        sessionStorage.setItem('schoolType', '210')
        this.props.getTocken(phone, tnId)
      } else {
        sessionStorage.setItem('schoolType', 'schoolType')
        // await this.props.fetchTokenValue()
      }
    } else {
      sessionStorage.removeItem('schoolType')
      // await this.props.fetchTokenValue()
    }
  }
  componentDidMount () {
    // this.props.fetchTokenValue()
  }

  xjjRouter = () => {
    const { requestState } = this.props
    let queryInfo = getQueryStrings()
    // let queryInfo = {
    //   token  : '887fa880-7702-425b-8f56-e4c7ae00956a',
    //   userId : '19264',
    //   tnId : '1016',
    //   teacherCode : '02e6e03c9a2541cbbee72fcecccaaca0',
    //   from : ''
    // }
    let params = ''
    let baseDataRoute = null
    if (queryInfo.action == 'xjj') {
      params = `action=${queryInfo.action}&code=${queryInfo.code}`
      baseDataRoute = (
        <Route
          path="/basicInfoManagement"
          render={props => <BaseData {...props} />}
        />
      )
    } else {
      params = `token=${queryInfo.token}&userId=${queryInfo.userId}&tnId=${queryInfo.tnId}&teacherCode=${queryInfo.teacherCode}&from=${queryInfo.from}`
    }

    if (requestState === 'done') {
      queryInfo = JSON.parse(sessionStorage.getItem('queryInfo'))
      params = `token=${queryInfo.token}&userId=${queryInfo.userId}&tnId=${queryInfo.tnId}&teacherCode=${queryInfo.teacherCode}&from=${queryInfo.from}`
    }
    return (
      <Routers
        keyLength={12}
      // basename={window.__MICRO_APP_BASE_ROUTE__ || '/cc'}
      >
        <React.Suspense fallback={<PageLoading />}>
          <Switch>
            <BasicLayout {...this.props}>
              <Route
                // exact
                path="/"
                render={() => {
                  return <Redirect to={`/model/elegant?${params}`} />
                }}
              />
              <Route
                path="/model"
                render={props => <ModelModule {...props} />}
              />
              <Route
                path="/substance"
                render={props => <SubstanceModule {...props} />}
              />
              <Route
                path="/facility"
                render={props => <FacilityModule {...props} />}
              />
              <Route
                path="/attendance"
                render={props => <AttendanceModule {...props} />}
              />
              <Route
                path="/mutual"
                render={props => <MutualModule {...props} />}
              />
              <Route
                path="/UploadRead"
                render={props => <UploadRead {...props} />}
              />
              {baseDataRoute}

              {/* <Redirect to="/model/elegant" /> */}
            </BasicLayout>
          </Switch>
        </React.Suspense>
      </Routers>
    )
  }

  czRouter = () => {
    const { from } = this.state
    return (
      <Routers keyLength={12} >
        <React.Suspense fallback={<PageLoading />}>
          <Switch>
            <BasicLayout {...this.props}>
              {from == 'saas' ? (
                <Route
                  // exact
                  path="/"
                  render={() => {
                    return (
                      <Redirect
                        to={`/substance/MoralEducation/ScoreDetail?token=${getQueryStrings().token
                          }&userId=${getQueryStrings().userId}&tnId=${getQueryStrings().tnId
                          }&teacherCode=${getQueryStrings().teacherCode}&from=${getQueryStrings().from
                          }`}
                      />
                    )
                  }}
                />
              ) : (
                <Route
                  // exact
                  path="/"
                  render={() => {
                    return (
                      <Redirect
                        to={`/model/elegant?token=${getQueryStrings().token
                          }&userId=${getQueryStrings().userId}&tnId=${getQueryStrings().tnId
                          }&teacherCode=${getQueryStrings().teacherCode}&from=${getQueryStrings().from
                          }`}
                      />
                    )
                  }}
                />
              )}
              <Route
                path="/model"
                render={props => <ModelModule {...props} />}
              />
              <Route
                path="/substance"
                render={props => <SubstanceModule {...props} />}
              />
              <Route
                path="/facility"
                render={props => <FacilityModule {...props} />}
              />
              <Route
                path="/attendance"
                render={props => <AttendanceModule {...props} />}
              />
              <Route
                path="/mutual"
                render={props => <MutualModule {...props} />}
              />
              <Route
                path="/UploadRead"
                render={props => <UploadRead {...props} />}
              />
              <Route path="/tv" render={props => <Tv {...props} />} />
              {/* <Redirect to="/model/elegant" /> */}
            </BasicLayout>
          </Switch>
        </React.Suspense>
      </Routers>
    )
  }

  render () {
    const { requestState } = this.props
    let queryInfo = getQueryStrings()

    return (
      <div>
        {queryInfo.action == 'xjj'
          ? requestState == 'done'
            ? this.xjjRouter()
            : null
          : this.czRouter()}
      </div>
    )
  }
}

Router.propTypes = {
  currentUser: PropTypes.object,
  setQueryInfo: PropTypes.func,
  fetchTokenValue: PropTypes.func,
  requestState: PropTypes.string,
  getTocken: PropTypes.func,
  schoolMenu: PropTypes.func,
  GetClassesAndGrades: PropTypes.func
}

export default Router
