import { observable, action } from 'mobx'
import { getQueryStrings } from '../utils'
// import { queryUserResource } from '../services/api'

let routerConfig = [
  {
    key: '10086',
    path: '/model',
    name: '云屏首页',
    label: 'Model',
    status: true,
    children: [
      {
        key: '56',
        path: '/model/elegant',
        name: '风采模式',
        label: 'Elegant',
        icon: 'iconfengcaimoshi',
        status: true
      },
      {
        key: '59',
        path: '/model/streamer',
        name: '横幅模式',
        label: 'Streamer',
        icon: 'iconhengfumoshi',
        status: true
      }
      ,
      {
        key: '60',
        path: '/model/examination',
        name: '考场模式',
        label: 'Examination',
        icon: 'iconkaochangmoshi',
        status: true
      }
    ]
  },
  {
    key: '10087',
    path: '/substance',
    label: 'Substance',
    name: '内容管理',
    status: true,
    children: [
      {
        key: '61',
        path: '/substance/countdown',
        label: 'Countdown',
        name: '倒计时',
        icon: 'icondaojishi',
        status: true
      },
      {
        key: '62',
        path: '/substance/exhibition',
        name: '风采展示',
        label: 'Exhibition',
        icon: 'iconfengcaimoshi',
        status: true
      },
      {
        key: '65',
        path: '/substance/notice',
        name: '通知公告',
        label: 'Notice',
        icon: 'icontongzhigonggao',
        status: true
      },
      {
        key: '68',
        path: '/substance/glory',
        label: 'Glory',
        name: '光荣榜',
        icon: 'iconbanjirongyu',
        status: true
      },
      {
        key: '13',
        path: '/substance/MoralEducation/ScoreDetail',
        label: 'ScoreDetail',
        name: '班级德育',
        icon: 'iconbanjideyu',
        status: true
      },
      // {
      //   key: '101104',
      //   path: '/substance/OnlineClassroom/onlineClassroom',
      //   label: 'ScoreDetail',
      //   name: '线上课堂',
      //   icon: 'iconic_panels_honor'
      // },
      {
        key: '73',
        path: '/substance/situation',
        label: 'situation',
        name: '学校概况',
        icon: 'iconxuexiaogaikuang',
        status: true
      },
      {
        key: '101',
        path: '/substance/ClassSurvey/detail',
        label: 'classSurvey',
        name: '班级概况',
        icon: 'iconbanjigaikuang',
        status: true
      }
    ]
  },
  {
    key: '10088',
    path: '/attendance',
    label: 'Attendance',
    name: '考勤管理',
    status: true,
    children: [
      {
        key: '74',
        path: '/attendance/inform',
        label: 'Inform',
        name: '学生考勤',
        icon: 'iconxueshengkaoqin1',
        status: true
      },
      // {
      //   key: '78',
      //   path: '/attendance/teachers',
      //   label: 'teachersAttendance',
      //   name: '教师考勤',
      //   icon: 'iconjiaoshikaoqin1',
      //   status: true
      // },
      // {
      //   key: '109',
      //   path: '/attendance/punch',
      //   label: 'teachersAttendance',
      //   name: '离校打卡',
      //   icon: 'iconlibandaka',
      //   status: true
      // }
    ]
  },
  // {
  //   key: '83',
  //   path: '/mutual',
  //   label: 'Mutual',
  //   name: '家校互通',
  //   status: true,
  //   children: [
  //     {
  //       key: '107',
  //       path: '/mutual/kinsfolk',
  //       label: 'Kinsfolk',
  //       name: '通话统计',
  //       icon: 'iconqinqingdianhua',
  //       status: true
  //     }
  //     // {
  //     //   key: '106',
  //     //   path: '/mutual/setting',
  //     //   label: 'Setting',
  //     //   name: '设置',
  //     //   icon: 'iconqinqingdianhua',
  //     //   status: true
  //     // }
  //   ]
  // },
  {
    key: '10089',
    path: '/facility',
    name: '系统管理',
    label: 'facility',
    status: true,
    children: [
      {
        key: '84',
        path: '/facility/equipmentClass',
        label: 'EquipmentClass',
        name: '班牌管理',
        icon: 'iconbanpaixiaopaishebei',
        status: true
      },
      // {
      //   key: '85',
      //   path: '/facility/EquipmentSchool',
      //   label: 'EquipmentSchool',
      //   name: '校牌管理',
      //   icon: 'iconzhiyingongneng',
      //   status: true
      // },
      {
        key: '86',
        path: '/facility/Material',
        label: 'EquipmentSchool',
        name: '素材库管理',
        icon: 'iconchakanxiangqing',
        status: true
      },
      {
        key: '87',
        path: '/facility/EquipmentUpdate',
        label: 'EquipmentUpdate',
        name: '数据同步',
        icon: 'iconzhiyingongneng',
        status: true
      }
      // {
      //   key: '103101',
      //   path: '/facility/authority',
      //   label: 'Authority',
      //   name: '权限管理',
      //   icon: 'iconic_panels_jurisdiction'
      // }
    ]
  }
  // {
  //   key: '105',
  //   name: '班级德育',
  //   path: '/substance/MoralEducation/ScoreDetail',
  //   label: 'ScoreDetail'
  // }
]

const baseDataRouter = [
  {
    key: '10090',
    path: '/basicInfoManagement',
    name: '云屏数据',
    label: 'basicInfoManagement',
    children: [
      {
        key: '105100',
        path: '/basicInfoManagement/course-management',
        label: 'course-management',
        name: '课程管理',
        icon: 'iconic_panels_brand1'
      },
      {
        key: '105101',
        path: '/basicInfoManagement/teacher-management',
        label: 'teacher-management',
        name: '教师管理',
        icon: 'iconic_panels_brand1'
      },
      {
        key: '105102',
        path: '/basicInfoManagement/student-management',
        label: 'student-management',
        name: '学生管理',
        icon: 'iconic_panels_brand1'
      },
      {
        key: '105103',
        path: '/basicInfoManagement/teach-management',
        label: 'teach-management',
        name: '教学时间',
        icon: 'iconic_panels_brand1'
      },
      {
        key: '105104',
        path: '/basicInfoManagement/classroom-management',
        label: 'classroom-management',
        name: '教室管理',
        icon: 'iconic_panels_brand1'
      },
      {
        key: '105105',
        path: '/basicInfoManagement/timetable-management',
        label: 'timetable-management',
        name: '课表管理',
        icon: 'iconic_panels_brand1'
      }
    ]
  }
]
// 请求数据

class RoutersStore {
  // routerConfig.concat(baseDataRouter)
  // @observable routerInfo =
  constructor(rootStore) {
    this.rootStore = rootStore
  }

  @observable routerInfo =
    getQueryStrings().action == 'xjj'
      ? routerConfig.concat(baseDataRouter)
      : routerConfig
  @observable userManage =
    JSON.parse(sessionStorage.getItem('userManage')) || {}
  //action
  @action.bound
  // fetchUserResource = flow(function*(data) {
  //   // const self = this
  //   console.log(data,'queryUserResource')
  //   try {
  //     const response = yield queryUserResource(data);
  //     console.log(response,'12121response')
  //     if (response.data.rtnCode === '0000000') {
  //       const { bizData } = response.data
  //       const ccList = bizData.list.filter(
  //         item => item.menuName == '智能云屏'
  //       )[0]
  //       const userManage = {
  //         isSuper: response.data.bizData.isSuper,
  //         manageRes: response.data.bizData.manageRes
  //       }
  //       sessionStorage.setItem('userManage', JSON.stringify(userManage))
  //       this.userManage = userManage
  //       this.diffListmapList([...routerConfig], ccList.children)
  //     }
  //   } catch (error) {
  //     //
  //     console.log('error :>> ', error)
  //   }
  // })

  diffListmapList = (dataList, list) => {
    // 将无限维数组进行展开
    const checkList = []

    const loopList = data => {
      data.forEach(item => {
        checkList.push(item.menuId)
        if (item.children && item.children.length > 0) {
          loopList(item.children)
        }
      })
    }
    loopList(list)
    const mapList = data => {
      data.forEach(item => {
        if (item.children && item.children.length > 0) {
          mapList(item.children)
        } else {
          if (checkList.indexOf(Number(item.key)) > -1) {
            item.status = true
          } else {
            item.status = false
          }
        }
      })
    }
    mapList(dataList)
    this.routerInfo = dataList
  }
}

export default RoutersStore
